import React from 'react'
import Layout from 'layouts/en'
import { Button, Div, Img, Text } from 'components'
import TrollBG from 'images/troll-bg.png'

export default () => {
  return (
    <Layout p={5}>
      <Div p={[4,6]} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
        <Text mt={[4,6]} fontSize={[4,6]}>Page not found</Text>
        <Button mt={[4,6]} boxShadow={'primary'} bg={'primary'} color={'white'} href={`/#`}>Back to homepage</Button>
      </Div>
    </Layout>
  )
}
